import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environment/environment';
@Injectable({
  providedIn: 'root'
})
export class SolicitudService {
  mapaSolidarioURL = environment.api_url;
  URLactual = null;
  ong: number;

  constructor(public http: HttpClient) { }
  getSolicitudes(org: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.URLactual = this.mapaSolidarioURL + 'solicitud/';
    if (org != null) {
      this.ong = org;
      this.URLactual = this.URLactual + '?ong=' + this.ong + '&estado=noLeido';
    }
    return this.http.get(this.URLactual, { headers });
  }
  postSolicitud(solicitud: any) {
    // const accessToken = localStorage.access_token_app;
    const headers = new HttpHeaders({
      // Authorization': 'Basic YXVndXN0bzphdWd1c3Rv'
      // 'Authorization': 'Basic' + btoa(`${this.usuario.username}:${this.usuario.pass}`)
      'Content-Type': 'application/json'
    });
    const body = JSON.stringify(solicitud);
    const URL = this.mapaSolidarioURL + 'solicitud2/';
    return this.http.post(URL, body, { headers });
  }
  getSolicitud(idSolicitud) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const URL = this.mapaSolidarioURL + 'solicitud/' + idSolicitud + '/';
    return this.http.get(URL, { headers });
  }
  // para editar solicitudes
  putSolicitud(idSolicitud, solicitud: any) {
    // const accessToken = localStorage.access_token_app;
    const headers = new HttpHeaders({
      // Authorization': 'Basic YXVndXN0bzphdWd1c3Rv'
      // 'Authorization': 'Basic' + btoa(`${this.usuario.username}:${this.usuario.pass}`)
      'Content-Type': 'application/json'
    });
    const body = JSON.stringify(solicitud);
    // console.log(body);
    const URL = this.mapaSolidarioURL + 'solicitud/' + idSolicitud;
    return this.http.put(URL, body, { headers });
  }
}
