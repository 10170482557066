import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-miong',
  templateUrl: './miong.component.html',
  styleUrls: ['./miong.component.scss']
})
export class MiongComponent implements OnInit {

  idusuario: number;
  constructor(public userService: UserService) { }

  ngOnInit() {
    this.userService.getIdUsuario(localStorage.getItem('currentUser'))
      .subscribe((res: any) => {
        if (res.results[0]) {
          this.idusuario = res.results[0].id;
        }
      }
    );
  }

}
