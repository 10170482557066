import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Categoria } from '../../core/models/categoria.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { NecesidadService } from 'src/app/core/services/necesidad.service';
import { CategoriaService } from 'src/app/core/services/categoria.service';

declare let L;
const iconRetinaUrl = 'assets/leaflet/images/marker-icon-2x.png';
const iconUrl = 'assets/leaflet/images/marker-icon.png';
const shadowUrl = 'assets/leaflet/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-unir-necesidad',
  templateUrl: './unir-necesidad.component.html',
  styleUrls: ['./unir-necesidad.component.scss']
})
export class UnirNecesidadComponent implements OnInit {

  ongForm: FormGroup;
  exito: boolean = null;

  logo: File;
  logoImagen: any;

  realInput: any;

  fileInputName: any;

  // @ViewChild('fileInput') fileInput: ElementRef;

  latitudCadena = '';
  latitudN: number;
  longitudCadena = '';
  longitudN: number;

  CATEGOS: Categoria[];

  CategosSeleccionadas: any[];
  error: any;
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public necesidadService: NecesidadService,
    public categoriaService: CategoriaService,
  ) { }

  ngOnInit() {
    this.ongForm = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.maxLength(150)]),
      descripcion: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      referente: new FormControl('', Validators.maxLength(150)),
      celular_referente: new FormControl('', Validators.maxLength(150)),
      total_familias: new FormControl('', Validators.maxLength(150)),
      total_platos: new FormControl('', Validators.maxLength(150)),
      email: new FormControl('', Validators.email),
      latitud: new FormControl(null, [Validators.required]),
      longitud: new FormControl(null, [Validators.required]),
    });
    this.mostrarmapa();
    this.categoriaService.getCategorias().subscribe((res: any) => {
      this.CATEGOS = res.results;
      // console.log(this.CATEGOS);
      // this.addCheckboxes();
      // console.log(this.ongForm.controls.categoriasAUX['controls']);
    });
    this.realInput = document.getElementById('real-input');
    this.fileInputName = 'No se ha seleccionado ninguna imagen';
  }

  insertarArchivo() {
    this.realInput.click();
  }



  obtCategorias() {
    this.categoriaService.getCategorias()
      .subscribe((res: any) => this.CATEGOS = res.results);
  }
 

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.logo = event.target.files[0];
      this.fileInputName = event.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.logoImagen = e.target.result;
      };
      reader.readAsDataURL(this.logo);
    }
  }

  toFormData(body) {
    const formData = new FormData();
    const keys = Object.keys(body);
    keys.forEach(key => {
      if (body[key] !== null) {
        if (key === 'categorias') {
          body[key].forEach(categoria => {
            formData.append('categorias', categoria);
          });
        } else if (typeof body[key] === 'object') {
          const subKeys = Object.keys(body[key]);
          subKeys.forEach((subKey) => {
            formData.append(`${key}.${subKey}`, body[key][subKey]);
          });
        } else {
          formData.append(key, body[key]);
        }
      }
    });
    return formData;
  }

  clearFile() {
    this.logo = null;
    // this.fileInput.nativeElement.value = '';
  }


  mostrarmapa() {
    const map = L.map('map').setView([-25.2938161, -57.5419462], 12);

    const calles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    let marcador;

    map.on('click', (e) => {

      if (marcador !== undefined) {
        map.removeLayer(marcador);
      }
      marcador = new L.marker([e.latlng.lat, e.latlng.lng]);
      map.addLayer(marcador);
      const latitudN = e.latlng.lat;
      const longitudN = e.latlng.lng;
      // var latitudCadena = this.convLat(latitudN);
      // var longitudCadena = this.convLon(longitudN);
      // console.log(latitudCadena, longitudCadena);
      this.ongForm.patchValue({
        latitud: latitudN.toString(),
        longitud: longitudN.toString(),
      });
    });
  }

  goBack() {
    this.location.back();
  }

  goHome() {
    this.router.navigate(['ollas-populares'], { });
  }

  onSubmit() {
    this.exito = null;
    this.CategosSeleccionadas = new Array();
   

    const formASubir = this.toFormData(this.ongForm.value);
    if (this.logo) {
      formASubir.append('imagen', this.logo);
    }
    this.necesidadService.postNecesidad(formASubir).subscribe(res => {
      if (res) {
        this.exito = true;
      }
    }, err => {
      this.exito = false;
      this.error = err;
    //  console.log(this.error)
      window.scrollTo(0, 0);
      this.ongForm.markAsTouched();
    });
  }
}
