import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public lottieConfig: any;
  private anim: any;
  private animationSpeed = 0.1;


  constructor() { }

  


handleAnimation(anim: any) {
    this.anim = anim;
    this.anim.setSpeed(0.5);
}

stop() {
    this.anim.stop();
}

play() {
    this.anim.play();
}

pause() {
    this.anim.pause();
}

setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
    console.log(speed);
}

ngOnInit() {


  this.lottieConfig = {
    path: 'assets/images/resources/data2.json',
    renderer: 'canvas',
    autoplay: true,
    loop: false,
    delay: 2
  };
 //  this.anim.setSpeed(0.5);


}

}
