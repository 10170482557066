import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


//  Servicios
import {
  OngService,
  NecesidadService,
  EventService,
  UserService,
  CategoriaService,
} from './services';

import {ScrollTopService} from './services/scrolltop.service'

// Modulos
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgbModule,
    HttpClientModule
  ],
  providers: [
    OngService,
    NecesidadService,
    EventService,
    UserService,
    CategoriaService,
    ScrollTopService
  ]
})
export class CoreModule { }
