import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ONG } from 'src/app/core/models/ong.model';
import { stringify } from 'querystring';
import { createRouterScroller } from '@angular/router/src/router_module';
import { environment } from '../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class NecesidadService {
  mapaSolidarioURL = environment.api_url;
  // para los filtros
  URLactual = null;
  // para los filtros en el mapa
  URLactualMapa = null;
  voluntarios: boolean;
  donantes: boolean;
  nombre: string;
  categoria: number;
  aprobado: boolean;
  usuario;

  constructor(public http: HttpClient) {
    this.voluntarios = null;
    this.donantes = null;
    this.nombre = null;
    this.categoria = null;
    this.aprobado = null;
    this.usuario = null;
  }

  // trae todas las ongs
  getONGsUsuario(iduser) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // quita todos los filtros
    this.voluntarios = null;
    this.donantes = null;
    this.categoria = null;
    this.nombre = null;
    this.aprobado = null;
    this.usuario = iduser;
    this.crearURL();
    return this.http.get(this.URLactual, { headers });
  }

  // trae todas las ongs
  getNecesidades() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // quita todos los filtros
    this.voluntarios = null;
    this.donantes = null;
    this.categoria = null;
    this.nombre = null;
    this.aprobado = null;
    this.usuario = null;
    this.crearURL();
    return this.http.get(this.URLactual, { headers });
  }
  // trae una ong especificada
  getNecesidad(idONG) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const URL = this.mapaSolidarioURL + 'necesidad/' + idONG;
    return this.http.get(URL, { headers });
  }

  postNecesidad(ong: any) {
    const accessToken = localStorage.access_token_app;
    const headers = new HttpHeaders({
      // Authorization': 'Basic YXVndXN0bzphdWd1c3Rv'
      // 'Authorization': 'Basic' + btoa(`${this.usuario.username}:${this.usuario.pass}`)
      Authorization: 'Bearer ' + accessToken,
    });
    const URL = this.mapaSolidarioURL + 'necesidad/';
    // descomentar para habilitar la autenticacion
   // return this.http.post(URL, ong, { headers });
    return this.http.post(URL, ong, {  });
  }

  // trae las ongs voluntarias
  getVoluntarios() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // const URL = this.mapaSolidarioURL + 'ong1/' + '?is_voluntariado=true';
    // return this.http.get(URL, {headers});
    this.voluntarios = true;
    this.donantes = null;
    this.crearURL();
    return this.http.get(this.URLactual, { headers });
  }
  // trae las ongs donantes
  getDonantes() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // const URL = this.mapaSolidarioURL + 'ong1/' + '?is_donacion=true';
    // return this.http.get(URL, {headers});
    this.donantes = true;
    this.voluntarios = null;
    this.crearURL();
    return this.http.get(this.URLactual, { headers });
  }

  // quita el filtro donantes y voluntarios (trae todos sin tocar los demas filtros)
  getDonantesYVoluntarios() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.donantes = null;
    this.voluntarios = null;
    this.crearURL();
    return this.http.get(this.URLactual, { headers });
  }
  // busca por el texto
  getONGsNombre(texto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // const URL = this.mapaSolidarioURL + '&search=' + texto;
    // return this.http.get(URL, {headers});
    this.nombre = texto;
    this.crearURL();
    return this.http.get(this.URLactual, { headers });
  }

  getporCategoria(id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // el + lo convierte a number
    this.categoria = +id;
    this.crearURL();
    return this.http.get(this.URLactual, { headers });
  }

  // quita el filtro de categorias y la busqueda pero mantiene el resto
  resetCategoria() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.categoria = null;
    this.nombre = null;
    this.crearURL();
    return this.http.get(this.URLactual, { headers });
  }
  paginar(page: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // si no existen filtros agrega el ?
    this.crearURL();
    if (
      this.voluntarios == null &&
      this.donantes == null &&
      this.nombre == null &&
      this.categoria == null &&
      this.aprobado == null &&
      this.usuario == null
    ) {
      this.URLactual = this.URLactual + '?';
    }
    return this.http.get(this.URLactual + '&page=' + page, { headers });
  }
  // trae todas las ongs para el mapa
  getNecesidadesMapa() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.voluntarios = null;
    this.donantes = null;
    this.categoria = null;
    this.nombre = null;
    this.aprobado = null;
    this.usuario = null;
    this.URLactualMapa = this.mapaSolidarioURL + 'necesidad-full/';
    return this.http.get(this.URLactualMapa, { headers });
  }


  // busca por el texto en el mapa
  getONGsNombreMapa(texto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.nombre = texto;
    this.crearURLMapa();
    return this.http.get(this.URLactualMapa, { headers });
  }
  getporCategoriaMapa(id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // el + lo convierte a number
    this.categoria = +id;
    this.URLactualMapa = this.mapaSolidarioURL + 'ong3/?';
    if (this.donantes != null) {
      this.URLactualMapa = this.URLactualMapa + '&is_donacion=' + this.donantes;
    }
    if (this.voluntarios != null) {
      this.URLactualMapa = this.URLactualMapa + '&is_voluntariado=' + this.voluntarios;
    }
    this.URLactualMapa = this.URLactualMapa + '&categorias=' + this.categoria;
    return this.http.get(this.URLactualMapa, { headers });
  }
  // quita el filtro de categorias y la busqueda pero mantiene el resto (en el mapa)
  resetCategoriaMapa() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.categoria = null;
    this.nombre = null;
    this.crearURLMapa();
    return this.http.get(this.URLactualMapa, { headers });
  }
  crearURL() {
    // si existe algun filtro
    if (
      this.voluntarios != null ||
      this.donantes != null ||
      this.nombre != null ||
      this.categoria != null ||
      this.aprobado != null ||
      this.usuario != null
    ) {
      this.URLactual = this.mapaSolidarioURL + 'necesidad/?';
      if (this.nombre != null) {
        this.URLactual = this.URLactual + 'search=' + this.nombre;
      }
      if (this.donantes != null) {
        this.URLactual = this.URLactual + '&is_donacion=' + this.donantes;
      }
      if (this.voluntarios != null) {
        this.URLactual =
          this.URLactual + '&is_voluntariado=' + this.voluntarios;
      }
      if (this.categoria != null) {
        this.URLactual = this.URLactual + '&categorias=' + this.categoria;
      }
      if (this.aprobado != null) {
        this.URLactual = this.URLactual + '&is_aprobado=' + this.aprobado;
      }
      if (this.usuario != null) {
        this.URLactual = this.URLactual + '&owner=' + this.usuario;
      }
    } else {
      this.URLactual = this.mapaSolidarioURL + 'necesidad/';
    }
  }
  crearURLMapa() {
    // si existe algun filtro
    if (
      this.voluntarios != null ||
      this.donantes != null ||
      this.nombre != null ||
      this.categoria != null ||
      this.aprobado != null
    ) {
      this.URLactualMapa = this.mapaSolidarioURL + 'necesidad-full/?';
      if (this.nombre != null) {
        this.URLactualMapa = this.URLactualMapa + 'search=' + this.nombre;
      }
      if (this.donantes != null) {
        this.URLactualMapa = this.URLactualMapa + '&is_donacion=' + this.donantes;
      }
      if (this.voluntarios != null) {
        this.URLactualMapa =
          this.URLactualMapa + '&is_voluntariado=' + this.voluntarios;
      }
      if (this.categoria != null) {
        this.URLactualMapa = this.URLactualMapa + '&categorias=' + this.categoria;
      }
      if (this.aprobado != null) {
        this.URLactualMapa = this.URLactualMapa + '&is_aprobado=' + this.aprobado;
      }
    } else {
      this.URLactualMapa = this.mapaSolidarioURL + 'necesidad/';
    }
  }

  // trae las ongs sin aprobar
  getNoAprobados() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.voluntarios = null;
    this.donantes = null;
    this.nombre = null;
    this.categoria = null;
    this.aprobado = true;
    this.usuario = null;
    this.crearURL();
    return this.http.get(this.URLactual, { headers });
  }

  aprobar(ong: any) {
    const accessToken = localStorage.access_token_app;
    const headers = new HttpHeaders({
      // Authorization': 'Basic YXVndXN0bzphdWd1c3Rv'
      // 'Authorization': 'Basic' + btoa(`${this.usuario.username}:${this.usuario.pass}`)
      Authorization: 'Bearer ' + accessToken,
    });
    const URL = this.mapaSolidarioURL + 'ong2/' + ong.id;
    return this.http.patch(URL, { is_aprobado: 'true' }, { headers });
  }
}
