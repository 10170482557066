import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environment/environment';


@Injectable({
  providedIn: 'root'
})
export class CategoriaService {
  mapaSolidarioURL = environment.api_url;

  constructor(public http: HttpClient) { }

  usuario = {
    username: 'augusto',
    pass: 'augusto'
  };


  getCategorias() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const URL = this.mapaSolidarioURL + 'categorias/';
    return this.http.get(URL, { headers });
  }
  getCategoria(idCat) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const URL = this.mapaSolidarioURL + 'categorias/' + idCat;
    return this.http.get(URL, { headers });
  }
}
