import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
// import { environment } from 'src/environments/environment';


export class AuthApp {
  access_token: string;
  token_type: string;
  expires_in: string;
  refresh_token: string;
  scope: string;
}


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
  error;
  authType;
  returnURL;
  formError = false;
  errorCred;

  exitoRegistro: boolean = null;

  authForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(
    private _userService: UserService,
    public router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }



  ngOnInit() {
    if (localStorage.currentUser != null) {
      this.router.navigate(['']);
    }
    this.route.url.subscribe(data => {
      this.authType = data[data.length - 1].path;
      if (this.authType === 'registro') {
        this.authForm.addControl(
          'email',
          new FormControl('', Validators.compose([Validators.email, Validators.required]))
        );
        this.authForm.addControl(
          'first_name',
          new FormControl('')
        );
        this.authForm.addControl(
          'last_name',
          new FormControl('')
        );
      }
      if (this.authType === 'logout') {
        this._userService.logout();
        this.location.back();
      }
    });
    this.route.queryParams.subscribe(data => {
      this.returnURL = data.returnUrl;
    });
  }

  onLogin() { }
  onSubmit() {
    this.exitoRegistro = null;
    if (this.authType === 'login') {
      if (!this.authForm.valid) {
        this.authForm.markAsTouched();
        this.formError = true;
      } else {
        this._userService
          .login(this.authForm.value['username'], this.authForm.value['password'])
          .subscribe(
            res => {
              const respuesta = res as AuthApp;
              const accessTokenApp = respuesta.access_token;
              localStorage.setItem('currentUser', this.authForm.value['username']);
              localStorage.setItem('access_token_app', accessTokenApp);
              localStorage.setItem('refresh_token_app', respuesta.refresh_token);
              this._userService.setAuth();
              if (this.returnURL == null) {
                this.location.back();
              } else {
                this.router.navigate([this.returnURL]);
              }
            },
            err => {
              this.errorCred = 'Usuario y/o contraseña no válidos.';
            }
          );
      }
    } else {
      if (!this.authForm.valid) {
        this.authForm.markAsTouched();
        this.formError = true;
        this.exitoRegistro = false;
      } else {
        this._userService.crearusuario(this.authForm.value).subscribe(res => {
          if (res) {
            this.exitoRegistro = true;
            /* console.log("Usuario creado con exito");
            this.router.navigate(['/login/']); */
          }
        }, err => {
          this.exitoRegistro = false;
          this.authForm.markAsTouched();
          console.log(err);
        });
      }
    }
  }
}
