import { Component, OnInit, Input } from '@angular/core';
import { EventService } from 'src/app/core/services/event.service';
import { OngService } from 'src/app/core/services/ong.service';
import { Event } from 'src/app/core/models/event.model';
import { ONG } from 'src/app/core/models/ong.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {

  // esta entrada definira si se listan todos los eventos de una ong, o todos si es null
  @Input() deONG: number;
  // todos los eventos
  eventos: Event[];
  // eventos que ya terminaron
  eventosantiguos: Event[];
  // eventos que estan por empezar
  eventosproximos: Event[];
  // el numero de paginas requerido
  paginas: number;
  pag: number;
  // la coleccion de paginas para iterar entre paginas con ngfor
  array: number[];

  // pagina proximos
  paginasProx: number;
  pagProx: number;
  arrayProx: number[];

  // pagina pasados
  paginasPas: number;
  pagPas: number;
  arrayPas: number[];

  // permisos para crear eventos
  permiso: boolean;

  constructor(public eventService: EventService, public ongService: OngService, public loading: NgxSpinnerService) { }

  ngOnInit() {
    // si el listado es llamado desde una ong se debe restringir a los no dueños
    setTimeout(() => this.loading.show(), 0);
    if (this.deONG != null) {
      this.ongService.getONG(this.deONG).subscribe((res: ONG) => {
        if (localStorage.getItem('currentUser') === res.owner) {
          this.permiso = true;
        } else {
          this.permiso = false;
        }
      });
    }
    this.eventService.getEventos(this.deONG).subscribe((res: any) => (this.eventos = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1));

    // cuando no es el listado de eventos de una ong se listan tambien los eventos pasados y futuros
    let cargados = 0;
    if (this.deONG == null) {
      this.permiso = true;
      this.eventService.getEventosProximos().subscribe((res: any) => (this.eventosproximos = res.results,
        this.paginasProx = Math.trunc(res.count / 24) + 1,
        this.arrayProx = Array.from(new Array(this.paginas), (x, i) => i + 1),
        this.pagProx = 1,
        cargados = cargados + 1,
        (cargados === 2) ? this.loading.hide() : null));
      this.eventService.getEventosPasados().subscribe((res: any) => (this.eventosantiguos = res.results,
        this.paginasPas = Math.trunc(res.count / 24) + 1,
        this.arrayPas = Array.from(new Array(this.paginas), (x, i) => i + 1),
        this.pagPas = 1,
        cargados = cargados + 1,
        (cargados === 2) ? this.loading.hide() : null));
    } else {
      this.loading.hide();
    }
  }

  buscar(event: any) {
    setTimeout(() => this.loading.show(), 0);
    this.eventService.getEventsNombre(event.target.value, this.deONG).subscribe((res: any) => (this.eventos = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1));
    let cargados = 0;
    // cuando no es el listado de eventos de una ong se listan tambien los eventos pasados y futuros
    if (this.deONG == null) {
      this.eventService.getEventsNombreProximos(event.target.value).subscribe((res: any) => (this.eventosproximos = res.results,
        this.paginasProx = Math.trunc(res.count / 24) + 1,
        this.arrayProx = Array.from(new Array(this.paginas), (x, i) => i + 1),
        this.pagProx = 1,
        cargados = cargados + 1,
        (cargados === 2) ? this.loading.hide() : null));
      this.eventService.getEventsNombrePasados(event.target.value).subscribe((res: any) => (this.eventosantiguos = res.results,
        this.paginasPas = Math.trunc(res.count / 24) + 1,
        this.arrayPas = Array.from(new Array(this.paginas), (x, i) => i + 1),
        this.pagPas = 1,
        cargados = cargados + 1,
        (cargados === 2) ? this.loading.hide() : null));
    } else {
      this.loading.hide();
    }
  }

  pagina(page: number) {
    setTimeout(() => this.loading.show(), 0);
    this.pag = page;
    this.eventService.paginar(page).subscribe((res: any) => (this.eventos = res.results, this.loading.hide()));
  }

  paginaProx(page: number) {
    setTimeout(() => this.loading.show(), 0);
    this.pagProx = page;
    this.eventService.paginarProx(page).subscribe((res: any) => (this.eventosproximos = res.results, this.loading.hide()));
  }

  paginaPas(page: number) {
    setTimeout(() => this.loading.show(), 0);
    this.pagPas = page;
    this.eventService.paginarPas(page).subscribe((res: any) => (this.eventosantiguos = res.results, this.loading.hide()));
  }
}
