import { Component, OnInit } from '@angular/core';
import { OngService } from 'src/app/core/services/ong.service';
import { UserService } from 'src/app/core/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'app-aprobarong',
  templateUrl: './aprobarong.component.html',
  styleUrls: ['./aprobarong.component.scss']
})
export class AprobarongComponent implements OnInit {

  ong;
  form;
  yaAprobado: boolean;
  permisos: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ongService: OngService,
    public userService: UserService
  ) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.ongService.getONG(id).subscribe(res => {
      this.ong = res;
      if (this.ong.is_aprobado) {
        this.yaAprobado = true;
      } else {
        // solo los usuarios administradores pueden aprobar ONGs
        this.permisos = false;
        this.userService.getIdUsuario(localStorage.currentUser).subscribe((res: any) => {
          const idUser = +res.results[0].id;
          this.userService.getUsuario(idUser).subscribe((result: any) => {
            if (result.is_superuser === true) {
              this.permisos = true;
            }
          });
        });
      }
      // console.log(this.ong);
    });
  }

  aprobarong() {
    this.ongService.aprobar(this.ong).subscribe(res => {
      if (res) {
        // console.log('exito');
        this.router.navigate(['/ongs/', this.ong.id]);
      }
    }, err => {
      console.log(err);
    });
  }
}
