import { Routes, RouterModule } from '@angular/router';
import { NopageComponent } from './shared/nopage/nopage.component';
import { HomeComponent } from './pages/home/home.component';





const appRoutes: Routes = [
    {path: '**', component: NopageComponent},
];



export const APP_ROUTES = RouterModule.forRoot(appRoutes);

