import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { EventService } from 'src/app/core/services/event.service';
import { UserService } from 'src/app/core/services/user.service';
import { OngService } from 'src/app/core/services/ong.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ONG } from 'src/app/core';

declare let L;
const iconRetinaUrl = 'assets/leaflet/images/marker-icon-2x.png';
const iconUrl = 'assets/leaflet/images/marker-icon.png';
const shadowUrl = 'assets/leaflet/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-crear-evento',
  templateUrl: './crear-evento.component.html',
  styleUrls: ['./crear-evento.component.scss']
})
export class CrearEventoComponent implements OnInit {
  eventForm: FormGroup;
  logo: File;
  logoImagen: any;
  realInput: any;
  fileInputName: any;
  // usuario a guardar en el evento
  user: number;
  exito: boolean = null;
  permisos = false;
  constructor(
    public eventService: EventService,
    public userService: UserService,
    public ongService: OngService,
    public location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // si se llama al componente desde una ong se asigna al evento la ong
    let ongid: number;
    // si se llama desde el listado de eventos se asigna al usuario logueado como owner
    if (this.route.snapshot.paramMap.get('id') != null) {
      ongid = +this.route.snapshot.paramMap.get('id');
      this.user = null;
      this.ongService.getONG(ongid).subscribe((res: ONG) => {
        if (res.owner === localStorage.getItem('currentUser')) {
          this.permisos = true;
        } else {
          this.permisos = false;
        }
      });
    } else {
      ongid = null;
      this.permisos = true;
      // se obtiene la id del usuario
      this.userService
        .getIdUsuario(localStorage.currentUser)
        .subscribe((res: any) => {
          if (res.results && res.results.length > 0) {
            this.user = +res.results[0].id;
          }
          // console.log(this.user);
          this.eventForm.patchValue({ owner: this.user });
        });
    }
    this.eventForm = new FormGroup({
      nombre: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      descripcion: new FormControl('', [Validators.required]),
      direccion: new FormControl('', [Validators.maxLength(100)]),
      // si es creado por un usuario en vez de una ong este atributo es true
      is_particular: new FormControl(false),
      // es null si es de un usuario
      ong: new FormControl(ongid),
      // es null si es de una ong
      owner: new FormControl(this.user),
      costo: new FormControl('gratuito', [
        Validators.required,
        Validators.maxLength(24)
      ]),
      fecha_inicio: new FormControl(null, [Validators.required]),
      fecha_fin: new FormControl(null),
      horario: new FormControl('', [Validators.maxLength(200)]),
      latitud: new FormControl(null, [Validators.required]),
      longitud: new FormControl(null, [Validators.required])
    });
    this.realInput = document.getElementById('real-input');
    this.fileInputName = 'No se ha seleccionado ninguna imagen';
    if (ongid === null) {
      this.eventForm.patchValue({
        is_particular: true
        // owner: localStorage.currentuser
      });
    }
    this.mostrarmapa();
  }

  insertarArchivo() {
    this.realInput.click();
  }

  // getidUsuario(nombreusuario: string) {
  //   let id: number;
  // }
  mostrarmapa() {
    const map = L.map('map').setView([-25.2838161, -57.6129462], 15);
    const calles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }
    ).addTo(map);

    let marcador;

    map.on('click', e => {
      if (marcador !== undefined) {
        map.removeLayer(marcador);
      }
      marcador = new L.marker([e.latlng.lat, e.latlng.lng]);
      map.addLayer(marcador);
      const latitudN = e.latlng.lat;
      const longitudN = e.latlng.lng;

      this.eventForm.patchValue({
        latitud: latitudN.toString(),
        longitud: longitudN.toString()
      });
    });
  }

  // cambia la referencia de logo a la imagen seleccionada en el formulario y utiliza un reader para la vista previa
  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.logo = event.target.files[0];
      this.fileInputName = event.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.logoImagen = e.target.result;
      };
      reader.readAsDataURL(this.logo);
    }
  }

  // transforma los datos del formulario en datos para guardar
  // se usa esto porque no usamos json en este formulario debido al logo
  toFormData(body) {
    const formData = new FormData();
    const keys = Object.keys(body);
    keys.forEach(key => {
      if (body[key] !== null) {
        if (typeof body[key] === 'object') {
          const subKeys = Object.keys(body[key]);
          // console.log(body[key]);
          subKeys.forEach(subKey => {
            formData.append(`${key}.${subKey}`, body[key][subKey]);
          });
        } else {
          formData.append(key, body[key]);
        }
      }
    });
    return formData;
  }

  onSubmit() {
    // se agrega el logo a los datos del formulario
    const formASubir = this.toFormData(this.eventForm.value);
    if (this.logo) {
      formASubir.append('imagen', this.logo);
    }
    // guarda el evento
    this.eventService.postEvento(formASubir).subscribe(res => {
      if (res) {
        this.exito = true;
      }
    }, err => {
      this.exito = false;
      this.eventForm.markAsTouched();
      console.log(err);
      // this.ongForm.addControl('categoriasAUX', new FormArray([]));
      // imprimir mensaje de error para crear
    });
  }
}
