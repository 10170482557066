import { Component, OnInit } from '@angular/core';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { Categoria } from 'src/app/core/models/categoria.model';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent implements OnInit {

  categorias: Categoria[];

  constructor(public CatService: CategoriaService) { }

  ngOnInit() {
    this.CatService.getCategorias().subscribe((res: any) => {
    this.categorias = res.results;
    });
  }


}
