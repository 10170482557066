import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OngService } from 'src/app/core/services/ong.service';
import { NecesidadService } from 'src/app/core/services/necesidad.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core';
import { ScrollTopService } from 'src/app/core/services/scrolltop.service';
import { NgxSpinnerService } from 'ngx-spinner';


declare let L;
const iconRetinaUrl = 'assets/leaflet/images/marker-icon-2x.png';
const iconUrl = 'assets/leaflet/images/marker-icon.png';
const shadowUrl = 'assets/leaflet/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-ongdetalle',
  templateUrl: './ongdetalle.component.html',
  styleUrls: ['./ongdetalle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OngdetalleComponent implements OnInit {

  ong;
  permisoSolicitud: boolean;
  permisoAprobar: boolean;
  path: string;
  detalle: string;

  constructor(
    private route: ActivatedRoute,
    public ongService: OngService,
    public necesidadService: NecesidadService,
    public userService: UserService,
    public loading: NgxSpinnerService,
    private scrollTopService: ScrollTopService) { }


  ngOnInit() {
    this.scrollTopService.setScrollTop();
    const id = +this.route.snapshot.paramMap.get('id');
    this.path = this.route.routeConfig.path;
    console.log(this.path)
    this.loading.show();
    if (this.path === 'necesidad/:id') {
      this.detalle = 'necesidad';
      this.getNecesidad(id);
    } else if (this.path === 'ongs/:id') {
      this.detalle = 'ongs';
      this.getOng(id);
    }


  }

  getOng(id) {
    this.ongService.getONG(id).subscribe(res => {
      this.ong = res;
      // permisos para ver la solicitudes de la ong
      // si es el mismo usuario que creo la ong
      if (this.ong.owner === localStorage.getItem('currentUser')) {
        this.permisoSolicitud = true;
      } else {
        this.permisoSolicitud = false;
      }
      // solamente los superuser pueden aprobar ongs
      this.permisoAprobar = false;
      this.userService.getIdUsuario(localStorage.currentUser).subscribe((res: any) => {
        const idUser = +res.results[0].id;
        this.userService.getUsuario(idUser).subscribe((result: any) => {
          if (result.is_superuser === true) {
            this.permisoAprobar = true;
          }
        });
      });
      // this.permisoAprobar = true;
      setTimeout(() => this.loading.hide(), 200);
      this.mostrarmapa();
    });
  }

  getNecesidad(id) {
    this.necesidadService.getNecesidad(id).subscribe(res => {
      this.ong = res;
      // this.permisoAprobar = true;
      setTimeout(() => this.loading.hide(), 200);
      this.mostrarmapa();
    });
  }

  mostrarmapa() {
    const map = L.map('map', { scrollWheelZoom: 'center' }).setView([this.ong.latitud, this.ong.longitud], 16);

    const calles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    const marcador = L.marker([this.ong.latitud, this.ong.longitud]).addTo(map);
    map.doubleClickZoom.disable();
  }

}
