import { Component, OnInit, Input } from '@angular/core';
import { OngService } from 'src/app/core/services/ong.service';
import { NecesidadService } from 'src/app/core/services/necesidad.service';
import { ONG } from 'src/app/core/models/ong.model';
import { Categoria } from 'src/app/core';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScrollTopService } from 'src/app/core/services/scrolltop.service';


declare let L;
const iconRetinaUrl = 'assets/leaflet/images/marker-icon-2x.png';
const iconUrl = 'assets/leaflet/images/marker-icon.png';
const shadowUrl = 'assets/leaflet/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

// para usar el $ de jQuery
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  // define que se debe listar solo donantes o solo voluntarios
  @Input() listaDe: string;

  display = 'none';
  // para el popup del mapa
  globito;
  // lista
  lista: ONG[];
  paginas: number;
  pag: number;
  // la coleccion de paginas para iterar entre paginas con ngfor
  array: number[];

  // mapa
  listado: ONG[];
  markers = Array();
  map: any;
  flag = false;
  detalle: string;
  // ong a mostrar
  data: ONG;
  aux: any;
  // categorias
  categorias: Categoria[];
  // categoria actual
  cate: Categoria;
  // indica que se llamo a una lista de solo donantes o de voluntarios
  solocategoria: boolean;
  constructor(
    public ongService: OngService,
    public necesidadService: NecesidadService,
    public categoriaService: CategoriaService,
    private route: ActivatedRoute,
    public loading: NgxSpinnerService,
    private scrollTopService: ScrollTopService
  ) {

  }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    setTimeout(() => this.loading.show(), 0);
    this.solocategoria = false;
    this.categoriaService.getCategorias().subscribe((res: any) => (this.categorias = res.results));
    // se listan todas las ongs
    if (this.listaDe === 'necesidades') {
      this.detalle = 'necesidad';
    } else {
      this.detalle = 'ongs';
    }

    if (this.listaDe == null) {
      this.ongService.getONGs().subscribe((res: any) => (this.lista = res.results,
        this.paginas = Math.trunc(res.count / 24) + 1,
        this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
        this.pag = 1,
        // esto se hace solo para que data no sea null y no haya problemas en el html
        this.data = this.lista[0],
        this.loading.hide()
      ));
      this.ongService.getONGsMapa().subscribe((resu: any) => {
        this.listado = resu.results;
        this.getMapa();
        this.loading.hide();
      });
    }
    // se listan ongs...
    if (this.listaDe != null) {
      // ... voluntarias
      if (this.listaDe === 'voluntarios') {
        this.solocategoria = true;
        setTimeout(() => this.loading.show(), 0);
        this.ongService.getVoluntarios().subscribe((res: any) => (this.lista = res.results,
          this.paginas = Math.trunc(res.count / 24) + 1,
          this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
          this.pag = 1,
          // esto se hace solo para que data no sea null y no haya problemas en el html
          this.data = this.lista[0]
        ));
        this.ongService.getVoluntariosMapa().subscribe((resu: any) => {
          this.listado = resu.results;
          this.getMapa();
          this.loading.hide();
        });
      } else if (this.listaDe === 'donantes') {
        // .. donantes
        this.solocategoria = true;
        setTimeout(() => this.loading.show(), 0);
        this.ongService.getDonantes().subscribe((res: any) => (this.lista = res.results,
          this.paginas = Math.trunc(res.count / 24) + 1,
          this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
          this.pag = 1,
          // esto se hace solo para que data no sea null y no haya problemas en el html
          this.data = this.lista[0]
        ));
        this.ongService.getDonantesMapa().subscribe((resu: any) => {
          this.listado = resu.results;
          this.getMapa();
          this.loading.hide();
        });
      } else if (this.listaDe === 'necesidades') {
        // .. Necesidades
        console.log('Map Necesidades')
        this.solocategoria = true;
        setTimeout(() => this.loading.show(), 0);
        this.necesidadService.getNecesidades().subscribe((res: any) => (this.lista = res.results,
          this.paginas = Math.trunc(res.count / 24) + 1,
          this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
          this.pag = 1,
          // esto se hace solo para que data no sea null y no haya problemas en el html
          this.data = this.lista[0]
        ));
        this.necesidadService.getNecesidadesMapa().subscribe((resu: any) => {
          console.log("Necesidades Mapa")
          this.listado = resu.results;
          this.getMapa();
          this.loading.hide();
        });
      }
    }
  }

  getcategoria(id: string) {
    setTimeout(() => this.loading.show(), 0);
    this.categoriaService
      .getCategoria(id)
      .subscribe((res: Categoria) => (this.cate = res));
    this.ongService.getporCategoria(id).subscribe((res: any) => (this.lista = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1
    ));
    this.ongService.getporCategoriaMapa(id).subscribe((res: any) => {
      this.listado = res.results;
      this.limpiarMapa();
      this.getMarcadores();
      this.loading.hide();
    });
  }

  limpiarMapa() {
    for (const i of this.markers) {
      this.map.removeLayer(i);
    }
    this.markers = [];
  }

  getMapa() {
    this.map = L.map('map', {
      center: [-25.3251632, -57.5876263],
      zoom: 10,
      scrollWheelZoom: false
    });

    const streets = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }
    ).addTo(this.map);
    this.getMarcadores();
  }

  voluntarios() {
    setTimeout(() => this.loading.show(), 0);
    this.ongService.getVoluntarios().subscribe((res: any) => (this.lista = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1
    ));
    this.ongService.getVoluntariosMapa().subscribe((resu: any) => {
      this.listado = resu.results;
      this.limpiarMapa();
      this.getMarcadores();
      this.loading.hide();
    });
  }

  donantes() {
    setTimeout(() => this.loading.show(), 0);
    this.ongService.getDonantes().subscribe((res: any) => (this.lista = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1
    ));
    this.ongService.getDonantesMapa().subscribe((resu: any) => {
      this.listado = resu.results;
      this.limpiarMapa();
      this.getMarcadores();
      this.loading.hide();
    });
  }

  buscar(event: any) {
    setTimeout(() => this.loading.show(), 0);
    this.ongService.getONGsNombre(event.target.value).subscribe((res: any) => (this.lista = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading.hide()));
    this.ongService.getONGsNombreMapa(event.target.value).subscribe((resu: any) => {
      this.listado = resu.results;
      this.limpiarMapa();
      this.getMarcadores();
      this.loading.hide();
    });
  }

  resetCategoria() {
    this.cate = null;
    setTimeout(() => this.loading.show(), 0);
    this.ongService.resetCategoria().subscribe((res: any) => (this.lista = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1));
    this.ongService.resetCategoriaMapa().subscribe((resu: any) => {
      this.listado = resu.results;
      this.limpiarMapa();
      this.getMarcadores();
      this.loading.hide();
    });
  }
  getOngs() {
    setTimeout(() => this.loading.show(), 0);
    this.ongService.getONGs().subscribe((res: any) => (this.lista = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1
    ));
    this.ongService.getONGsMapa().subscribe((resu: any) => {
      this.listado = resu.results;
      this.limpiarMapa();
      this.getMarcadores();
      this.cate = null;
      this.loading.hide();
    });
  }

  pagina(page: number) {
    this.pag = page;
    console.log("paginacion")
    if (this.listaDe === 'necesidades') {
      this.necesidadService.paginar(page).subscribe((res: any) => (this.lista = res.results, window.scroll(0, 0)));
    } else {
      this.ongService.paginar(page).subscribe((res: any) => (this.lista = res.results, window.scroll(0, 0)));
    }
  }

  getMarcadores = () => {
    let marcador;
    let cont = 1;
    // tslint:disable-next-line:prefer-for-of
    for (let j = 0; j < this.listado.length; j++) {
      const i = this.listado[j];
      console.log(j);
      if (i.latitud !== null) {
        marcador = new L.marker([i.latitud, i.longitud], {
          nombre: i.nombre,
          horario: i.horario,
          email: i.email,
          contacto: i.contacto,
          direccion: i.direccion,
          is_aprobado: i.is_aprobado,
          is_donacion: i.is_donacion,
          is_voluntariado: i.is_voluntariado
        });
        marcador.on('click', e => {
          this.data = i;
          // console.log(this.data.id);
          // console.log('Evento', e);
          // this.display = 'block';
          $('#modalONGDetalle').modal('show');

        });
        marcador.addTo(this.map);
        // console.log('ONG', cont, i);
        cont++;
        this.markers.push(marcador);
      }
    }
    // console.log(this.markers.length);
  }

  addInfo(i) {
    this.data = i;
  }

  marcarmapa(ongMarcada: ONG) {
    this.globito = L.popup()
      .setLatLng([ongMarcada.latitud, ongMarcada.longitud])
      .setContent(ongMarcada.nombre)
      .openOn(this.map);
  }
  desmarcarmapa() {
    this.map.closePopup();
  }
}
