import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient) { }

	getToken() {
		return window.localStorage['token'];
	}

	saveToken(token) {
		window.localStorage['token'] = token;
	}

	destroyToken() {
		window.localStorage.removeItem('token');
	}
}
