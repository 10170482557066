import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-miperfil',
  templateUrl: './miperfil.component.html',
  styleUrls: ['./miperfil.component.scss']
})
export class MiperfilComponent implements OnInit {
  user;
  userId;

  constructor(
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.userService
      .getIdUsuario(localStorage.currentUser)
      .subscribe((res: any) => {
        this.userId = +res.results[0].id;
        // console.log(this.userId);

        this.userService.getUsuario(this.userId).subscribe((result: any) => {
          this.user = result;
          // console.log(' datos de usuario', this.user);
        });
      });
  }
}
