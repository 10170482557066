import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-margen',
  templateUrl: './margen.component.html',
  styleUrls: ['./margen.component.scss']
})
export class MargenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
