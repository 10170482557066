import { Component, OnInit, Input } from '@angular/core';
import { SolicitudAyuda } from '../../core/models/solicitudayuda.model';
import { SolicitudService } from '../../core/services/solicitud.service';
import { Router } from '@angular/router';

// para usar el $ de jQuery
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-l-solicitudes',
  templateUrl: './l-solicitudes.component.html',
  styleUrls: ['./l-solicitudes.component.scss']
})
export class LSolicitudesComponent implements OnInit {
  // esta entrada definira si se listan todos los eventos de una ong, o todos si es null
  @Input() deONG: number;
  soli: SolicitudAyuda;
  // por si se requiere paginado en solicitudes
  // el numero de paginas requerido
  // paginas: number;
  // pag: number;
  // la coleccion de paginas para iterar entre paginas con ngfor
  // array: number[];
  solicitudes: SolicitudAyuda[];
  constructor(public solicitudService: SolicitudService) {}

  ngOnInit() {
    this.solicitudService
      .getSolicitudes(this.deONG)
      .subscribe((res: any) => (this.solicitudes = res.results));
  }

  setSolicitudEnModal(solicitud: SolicitudAyuda) {
    this.soli = solicitud;
  }

  solicitudLeida() {
    this.soli.ong = this.soli.ong.id;
    this.soli.estado = 'leido';
    const idSol = this.soli.id;
    this.solicitudService.putSolicitud(idSol, this.soli).subscribe((edi: any) => (
      // simplemente recarga las solicitudes
      this.solicitudService
      .getSolicitudes(this.deONG)
      .subscribe((res: any) => (this.solicitudes = res.results))
    ));
  }
}
