import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  mapaSolidarioURL = environment.api_url;
  // endpoints para eventos todos, proximos y pasados respectivamente
  URLactual = null;
  URLactualProx = null;
  URLactualPas = null;
  nombre: string;
  ong: number;

  constructor(public http: HttpClient) { }
  getEventos(org: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.URLactual = this.mapaSolidarioURL + 'events/';
    if (org != null) {
      this.ong = org;
      this.URLactual = this.URLactual + '?ong=' + this.ong;
    }
    return this.http.get(this.URLactual, { headers });
  }
  getEventosPasados() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.URLactualPas = this.mapaSolidarioURL + 'eventsantiguos/';
    return this.http.get(this.URLactualPas, { headers });
  }
  getEventosProximos() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.URLactualProx = this.mapaSolidarioURL + 'events_prox/';
    return this.http.get(this.URLactualProx, { headers });
  }
  postEvento(evento: any) {
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      Authorization: 'Basic YXVndXN0bzphdWd1c3Rv'
    });
    // const body = JSON.stringify(evento);
    const URL = this.mapaSolidarioURL + 'events2/';
    return this.http.post(URL, evento, { headers });
  }
  getEvento(idEvent) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const URL = this.mapaSolidarioURL + 'events/' + idEvent + '/';
    return this.http.get(URL, { headers });
  }

  getEventsNombre(texto, org: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.nombre = texto;
    this.URLactual = this.mapaSolidarioURL + 'events/?search=' + this.nombre;
    if (org != null) {
      this.ong = org;
      this.URLactual = this.URLactual + '&ong=' + this.ong;
    }
    return this.http.get(this.URLactual, { headers });
  }

  getEventsNombreProximos(texto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.nombre = texto;
    this.URLactualProx = this.mapaSolidarioURL + 'events_prox/?search=' + this.nombre;
    return this.http.get(this.URLactualProx, { headers });
  }

  getEventsNombrePasados(texto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.nombre = texto;
    this.URLactualPas = this.mapaSolidarioURL + 'eventsantiguos/?search=' + this.nombre;
    return this.http.get(this.URLactualPas, { headers });
  }


  paginar(page: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.URLactual = this.mapaSolidarioURL + 'events/?';
    if (this.nombre != null) {
      this.URLactual = this.URLactual + 'search=' + this.nombre;
    }
    if (this.ong != null) {
      this.URLactual = this.URLactual + '&ong=' + this.ong;
    }
    return this.http.get(this.URLactual + '&page=' + page, { headers });
  }

  paginarProx(page: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.URLactualProx = this.mapaSolidarioURL + 'events_prox/?';
    if (this.nombre != null) {
      this.URLactualProx = this.URLactual + 'search=' + this.nombre;
    }
    return this.http.get(this.URLactualProx + '&page=' + page, { headers });
  }

  paginarPas(page: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.URLactualPas = this.mapaSolidarioURL + 'eventsantiguos/?';
    if (this.nombre != null) {
      this.URLactualPas = this.URLactualPas + 'search=' + this.nombre;
    }
    return this.http.get(this.URLactualPas + '&page=' + page, { headers });
  }
}
