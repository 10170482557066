import { Component, OnInit, Input } from '@angular/core';
import { OngService } from 'src/app/core/services/ong.service';
import { ONG } from 'src/app/core/models/ong.model';
import { Categoria } from 'src/app/core/models/categoria.model';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { ActivatedRoute } from '@angular/router';
import { SolicitudComponent } from '../solicitud/solicitud.component';
import { NgxSpinnerService } from 'ngx-spinner';
import {Location} from '@angular/common';
import { ScrollTopService } from 'src/app/core/services/scrolltop.service';


@Component({
  selector: 'app-l-ongs',
  templateUrl: './l-ongs.component.html',
  styleUrls: ['./l-ongs.component.scss']
})
export class LONGsComponent implements OnInit {

  // esta entrada definira si se listan las ongs por un usuario
  @Input() usuario: string;
  // define que se debe listar solo donantes o solo voluntarios
  @Input() listaDe: string;

  listado: ONG[];
  categorias: Categoria[];
  // la categoria actual
  cat: Categoria;
  // el numero de paginas requerido
  paginas: number;
  // pagina actual
  pag: number;
  // la coleccion de paginas para iterar entre paginas con ngfor
  array: number[];
  // indica que se llamo a una lista de solo donantes o de voluntarios
  solocategoria: boolean;
  cateogria: string;

  constructor(
    private route: ActivatedRoute,
    public ongService: OngService,
    public categoriaService: CategoriaService,
    public loading: NgxSpinnerService,
    private location: Location,
    private scrollTopService: ScrollTopService) { }
 

  ngOnInit() {

    setTimeout(() => this.loading.show(), 0);
    this.scrollTopService.setScrollTop();
    this.solocategoria = false;
    this.categoriaService.getCategorias().subscribe((res: any) => this.categorias = res.results);
    if (this.usuario != null) {
      this.ongService.getONGsUsuario(this.usuario).subscribe((res: any) => (this.listado = res.results,
        this.paginas = Math.trunc(res.count / 24) + 1,
        this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
        this.pag = 1,
        this.loading.hide()));
    } else {
      if (this.route.routeConfig.path === 'ongs/categoria/:id') {
        this.route.paramMap.subscribe(params => {
          this.cateogria = params.get('id')
          this.getcategoria(this.cateogria);
        });
      } else {
      if (this.route.routeConfig.path === 'coronavirus') {
        this.route.paramMap.subscribe(params => {
          this.cateogria = '24';
          this.getcategoria(this.cateogria);
        });
      } else {
        this.ongService.getONGs().subscribe((res: any) => (this.listado = res.results,
          this.paginas = Math.trunc(res.count / 24) + 1,
          this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
          this.pag = 1,
          this.loading.hide()));
      }
      }
    }
    if (this.listaDe != null) {
      setTimeout(() => this.loading.show(), 0);
      if (this.listaDe === 'voluntarios') {
        this.solocategoria = true;
        this.ongService.getVoluntarios().subscribe((res: any) => (this.listado = res.results,
          this.paginas = Math.trunc(res.count / 24) + 1,
          this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
          this.pag = 1,
          this.loading.hide()));
      } else if (this.listaDe === 'donantes') {
        this.solocategoria = true;
        this.ongService.getDonantes().subscribe((res: any) => (this.listado = res.results,
          this.paginas = Math.trunc(res.count / 24) + 1,
          this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
          this.pag = 1,
          this.loading.hide()));
      }
    }
  }

  buscar(event: any) {
    setTimeout(() => this.loading.show(), 0);
    this.ongService.getONGsNombre(event.target.value).subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading.hide()));
  }

  voluntarios() {
    setTimeout(() => this.loading.show(), 0);
    this.ongService.getVoluntarios().subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading.hide()));
  }

  donantes() {
    setTimeout(() => this.loading.show(), 0);
    this.ongService.getDonantes().subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading.hide()));
  }
  // filtra por categoria
  getcategoria(id: string) {
    setTimeout(() => this.loading.show(), 0);

    this.categoriaService.getCategoria(id).subscribe((res: Categoria) => this.cat = res);
    this.ongService.getporCategoria(id).subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.location.replaceState('ongs/categoria/' + id),
      this.loading.hide()));
  }

  pagina(page: number) {
    this.pag = page;
    setTimeout(() => this.loading.show(), 0);
    this.ongService.paginar(page).subscribe((res: any) => (this.listado = res.results, this.loading.hide()));
  }

  reset() {
    this.cat = null;
    setTimeout(() => this.loading.show(), 0);
    if (this.usuario != null) {
      this.ongService.getONGsUsuario(this.usuario).subscribe((res: any) => (this.listado = res.results,
        this.paginas = Math.trunc(res.count / 24) + 1,
        this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
        this.pag = 1,
        this.loading.hide()));
    } else {
      this.ongService.getONGs().subscribe((res: any) => (this.listado = res.results,
        this.paginas = Math.trunc(res.count / 24) + 1,
        this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
        this.pag = 1,
        this.loading.hide()));
    }
  }
  resetCategoria() {
    this.cat = null;
    setTimeout(() => this.loading.show(), 0);
    this.ongService.resetCategoria().subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading.hide()));
  }
}
