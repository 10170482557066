import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnirOngComponent } from './unir-ong.component';

@NgModule({
  declarations: [
    UnirOngComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    UnirOngComponent,
  ]
})
export class UnirOngModule { }
