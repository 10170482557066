import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/core/services/event.service';
import { ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/core/models/event.model';

declare let L;
const iconRetinaUrl = 'assets/leaflet/images/marker-icon-2x.png';
const iconUrl = 'assets/leaflet/images/marker-icon.png';
const shadowUrl = 'assets/leaflet/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-eventodetalle',
  templateUrl: './eventodetalle.component.html',
  styleUrls: ['./eventodetalle.component.scss']
})
export class EventodetalleComponent implements OnInit {

  evento: Event;
  fechaI: Date;
  fechaF: Date;
  mesI;
  mesF;

  constructor(private route: ActivatedRoute, public eventService: EventService) { }

  ngOnInit() {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const id = +this.route.snapshot.paramMap.get('id');
    this.eventService.getEvento(id).subscribe((res: Event) => {
      this.evento = res;
      // le agregue las horas para corregir un error de impresion con las
      // fechas en la que se atrasaban un dia
      if (this.evento.fecha_inicio) {
        this.fechaI = new Date(this.evento.fecha_inicio + 'T12:00:00');
        console.log(this.fechaI);
        this.mesI = months[this.fechaI.getMonth()];
      }
      if (this.evento.fecha_fin) {
        this.fechaF = new Date(this.evento.fecha_fin + 'T12:00:00');
        this.mesF = months[this.fechaF.getMonth()];
      }
      this.mostrarmapa();
    });
  }
  mostrarmapa() {
    const map = L.map('map', { scrollWheelZoom: 'center' }).setView([this.evento.latitud, this.evento.longitud], 16);

    const calles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    const marcador = L.marker([this.evento.latitud, this.evento.longitud]).addTo(map);
    map.doubleClickZoom.disable();
  }

}
