import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

// Routes
import { PAGES_ROUTES } from './pages.routes';

// Components
import { HomeComponent } from './home/home.component';
import { PageComponent } from './page.component';
import { RouterModule } from '@angular/router';
// import { MapaComponent } from './mapa/mapa.component';
import { OngdetalleComponent } from './ongdetalle/ongdetalle.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { LONGsComponent } from './l-ongs/l-ongs.component';
import { EventosComponent } from './eventos/eventos.component';
import { MapComponent } from './map/map.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SolicitudComponent } from './solicitud/solicitud.component';
import { CrearEventoComponent } from './crear-evento/crear-evento.component';
import { EventodetalleComponent } from './eventodetalle/eventodetalle.component';
import { LSolicitudesComponent } from './l-solicitudes/l-solicitudes.component';
import { AprobarongComponent } from './aprobarong/aprobarong.component';
import { MiperfilComponent } from './miperfil/miperfil.component';
import { MiongComponent } from './miong/miong.component';
import { ListafooterComponent } from './listafooter/listafooter.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ListatablaComponent } from './listatabla/listatabla.component';
import { LottieAnimationViewModule } from 'ng-lottie';

// import { GrillaComponent } from './grilla/grilla.component';


// Modulo de cargando
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    PageComponent,
    HomeComponent,
    OngdetalleComponent,
    CategoriaComponent,
    LONGsComponent,
    EventosComponent,
    SolicitudComponent,
    CrearEventoComponent,
    EventodetalleComponent,
    MapComponent,
    LSolicitudesComponent,
    AprobarongComponent,
    MiperfilComponent,
    AprobarongComponent,
    MiongComponent,
    ListafooterComponent,
    ResetPasswordComponent,
    ListatablaComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PAGES_ROUTES,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    LottieAnimationViewModule.forRoot()
   
  ],
  exports: [PageComponent, HomeComponent, RouterModule, NgxSpinnerModule, ]
})
export class PagesModule { }
