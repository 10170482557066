import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { SolicitudService } from 'src/app/core/services/solicitud.service';
import { ONG } from '../../core/models/ong.model';
import { OngService } from 'src/app/core';

// para usar el $ de jQuery
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styleUrls: ['./solicitud.component.scss']
})
export class SolicitudComponent implements OnInit {
  @Input() ongnumero: number;
  solicitudForm: FormGroup;
  // referencia a la ong a la que se crea su solicitud
  ongref: ONG;
  nombreRef: string;
  cargando = false;

  constructor(
    public eventService: SolicitudService,
    public ongService: OngService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
    // si se llama al componente desde una ong, se asigna a la solicitud la ong
    let ongid: number;
    if (this.route.snapshot.paramMap.get('id') != null) {
      ongid = +this.route.snapshot.paramMap.get('id');
      this.ongService.getONG(ongid).subscribe((res: ONG) => this.ongref = res);
    } else {
      ongid = null;
    }
    this.solicitudForm = new FormGroup({
      tipo: new FormControl('', [
        Validators.required,
        Validators.maxLength(500)
      ]),
      ong: new FormControl(ongid),
      nombre: new FormControl('', [
        Validators.required,
        Validators.maxLength(150)
      ]),
      edad: new FormControl(),
      phone: new FormControl('', [Validators.required, Validators.maxLength(150)]),
      email: new FormControl('', Validators.required),
      estado: new FormControl('noLeido')

    });
  }
  toFormData(body) {
    const formData = new FormData();
    const keys = Object.keys(body);
    keys.forEach(key => {
      if (body[key] !== null) {
        if (typeof body[key] === 'object') {
          const subKeys = Object.keys(body[key]);
          subKeys.forEach(subKey => {
            formData.append(`${key}.${subKey}`, body[key][subKey]);
          });
        } else {
          formData.append(key, body[key]);
        }
      }
    });
    return formData;
  }

  onSubmit() {
    // console.log(this.solicitudForm.value);
    // guarda el evento
    this.cargando = true;
    const formASubir = this.solicitudForm.value;
    this.nombreRef = formASubir.nombre;
    this.eventService.postSolicitud(formASubir).subscribe(
      res => {
        this.cargando = false;
        if (res) {
          console.log(res);
          $('#modalMensaje').modal('show');
          // this.router.navigate(['/ongs/', this.solicitudForm.value['ong']]);
          // imprimir mensaje de creado correctamente
          // this.goBack();
        }
      },
      err => {
        this.cargando = false;
        window.scrollTo(0, 0);
        this.solicitudForm.markAsTouched();
        console.log(err);
        // this.ongForm.addControl('categoriasAUX', new FormArray([]));
        // imprimir mensaje de error para crear
      }
    );
  }
  /* goBack(): void {
    this.location.back();
  } */
}
