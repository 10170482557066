import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { HeaderComponent } from './layout/header.component';
import { FooterComponent } from './layout/footer.component';
import { NopageComponent } from './nopage/nopage.component';
import { RouterModule } from '@angular/router';
import { MargenComponent } from './margen/margen.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NopageComponent,
    MargenComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NopageComponent,
    MargenComponent
  ]
})
export class SharedModule { }
