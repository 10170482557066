import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from './page.component';
import { HomeComponent } from './home/home.component';
import { MapComponent } from './map/map.component';
import { NopageComponent } from '../shared/nopage/nopage.component';
import { LONGsComponent } from './l-ongs/l-ongs.component';
import { OngdetalleComponent } from './ongdetalle/ongdetalle.component';
import { MiongComponent } from './miong/miong.component';
import { SolicitudComponent } from './solicitud/solicitud.component';
import { ListafooterComponent } from './listafooter/listafooter.component';
import { ListatablaComponent } from './listatabla/listatabla.component';

// Augusto
import { UnirOngComponent } from '../pages/unir-ong/unir-ong.component';
import { UnirNecesidadComponent } from '../pages/unir-necesidad/unir-necesidad.component';
import { AuthComponent } from '../auth/auth.component';
import { EventosComponent } from './eventos/eventos.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { EventodetalleComponent } from './eventodetalle/eventodetalle.component';
import { CrearEventoComponent } from './crear-evento/crear-evento.component';
import { AprobarongComponent } from '../pages/aprobarong/aprobarong.component';
import { MiperfilComponent } from './miperfil/miperfil.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { from } from 'rxjs';

const pagesRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'mapa', component: MapComponent },

      { path: 'ongs', component: LONGsComponent },
      { path: 'ongs/categoria/:id', component: LONGsComponent },
      { path: 'coronavirus', component: LONGsComponent },
      { path: 'ongs/:id', component: OngdetalleComponent },
      { path: 'necesidad/:id', component: OngdetalleComponent },
      { path: 'ongs-donantes', component: ListafooterComponent },
      { path: 'mapa-necesidades', component: ListafooterComponent },
      { path: 'ollas-populares',   redirectTo: '/mapa-necesidades', },
      { path: 'ongs-voluntarios', component: ListafooterComponent },
      { path: 'mapa-donantes', component: ListafooterComponent },
      { path: 'mapa-voluntarios', component: ListafooterComponent },
      { path: 'datos-ongs', component: ListatablaComponent },
      {
        path: 'ongs/aprobar/:id',
        component: AprobarongComponent,
        canActivate: [AuthGuard]
      },
      { path: 'eventos', component: EventosComponent },
      { path: 'eventos/:id', component: EventodetalleComponent },
      { path: 'miong', component: MiongComponent, canActivate: [AuthGuard] },
      {
        path: 'perfil',
        component: MiperfilComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'crear/eventos',
        component: CrearEventoComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'crear/eventos/:id',
        component: CrearEventoComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'crear/solicitud/:id',
        component: SolicitudComponent
      },
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: 'unir-ong',
        component: UnirOngComponent,
        pathMatch: 'full',
       // canActivate: [AuthGuard] // Activar para que funcione el login
      },
      {
        path: 'unir-olla',
        component: UnirNecesidadComponent,
        pathMatch: 'full',
       // canActivate: [AuthGuard] // Activar para que funcione el login
      },
      { path: 'login', component: AuthComponent },
      { path: 'registro', component: AuthComponent },
      { path: 'logout', component: AuthComponent },
      { path: 'password-reset', component: ResetPasswordComponent }
    ]
  }
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
