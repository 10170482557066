import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { of, BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  mapaSolidarioURL = environment.api_url;

  private currentUserSubject = new BehaviorSubject({});

  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isAthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAthenticatedSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(public http: HttpClient, private tokenService: TokenService) { }

  populate() {
    const URL = this.mapaSolidarioURL + '/accounts/me/';
    if (this.tokenService.getToken()) {
      this.http.get(URL).subscribe(
        user => {
          this.currentUserSubject.next(user);
          this.isAthenticatedSubject.next(true);
        },
        err => {
          this.purgeAuth();
        }
      );
    } else {
      this.isAthenticatedSubject.next(false);
    }
  }

  setAuth() {
    this.isAthenticatedSubject.next(true);
  }

  purgeAuth() {
    // this.tokenService.destroyToken();
    // this.currentUserSubject.next({});
    this.isAthenticatedSubject.next(false);
  }

  getCurrentUser() {
    return localStorage.getItem('currentUser');
  }

  estaAutenticado() {
    if (
      localStorage.getItem('access_token_app') === null ||
      localStorage.getItem('access_token_app').length === 0 ||
      localStorage.getItem('currentUser') == null
    ) {
      return false;
    } else {
      return true;
    }
  }

  crearusuario(usuario) {
    const URL = this.mapaSolidarioURL + 'users/register/';
    const body = JSON.stringify(usuario);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(URL, usuario, { headers });
  }

  login(usuario, contrasenha) {
    let body = {
      grant_type: 'password',
      client_id: '2yDLEUuw8wYKdZ1HQfwxICBqDR0tMvUW0KeJ5GQi',
      username: usuario,
      password: contrasenha
    };
    const URL = this.mapaSolidarioURL + 'auth/token/';
    return this.http.post(URL, body);
  }

  logout() {
    try {
      localStorage.removeItem('access_token_app');
      localStorage.removeItem('refresh_token_app');
      localStorage.removeItem('currentUser');
      this.isAthenticatedSubject.next(false);
    } catch (err) {
      console.log(err);
    }
  }

  getIdUsuario(nombre: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const URL = this.mapaSolidarioURL + 'users/?username=' + nombre;
    return this.http.get(URL, { headers });
  }
  getUsuario(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const URL = this.mapaSolidarioURL + 'users/' + id + '/';
    return this.http.get(URL, { headers });
  }

  passwordReset(body) {
    const URL = this.mapaSolidarioURL + 'auth/password-reset/';
    return this.http.post(URL, body);
  }
  passwordResetConfirm(body) {
    const URL = this.mapaSolidarioURL + 'auth/password-reset/confirm/';
    return this.http.post(URL, body);
  }
}
