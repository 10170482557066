import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public token;
  public message;
  public error;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) { }

  // formulario
  resetForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl(''),
    passwordConfirm: new FormControl()
  });

  ngOnInit() {
    this.route.queryParamMap.subscribe((data: any) => {
      if (data['params'].token) {
        this.token = data['params'].token;
      }
    });
    this.resetForm.controls['passwordConfirm'].setValidators([, this.noEqual.bind(this.resetForm)]);
  }

  onSubmit() {
    if (this.token) {
      const body = {
        token: this.token,
        password: this.resetForm.value.password
      };
      this.loading = true;
      this.error = null;
      // console.log(body);
      // para confirmar el reinicio de contraseña
      this.userService.passwordResetConfirm(body).subscribe(
        data => {
          // console.log(data);
          this.loading = false;
          this.router.navigateByUrl('/');
        }, err => {
          this.loading = false;
          console.log(err);
          this.error = err.password;
        }
      );
    }

    if (this.resetForm.value.email) {
      // console.log(this.loading)
      this.loading = true;
      this.error = null;
      // cuando se envia el formulario
      this.userService.passwordReset(this.resetForm.value).subscribe((data: any) => {
        // cuando se recibe el mail
        if (data.status) {
          // console.log(this.resetForm);
          this.loading = false;
          this.message = 'Revisa tu correo para cambiar tu contraseña';
        }
      },
      err => {
        this.loading = false;
        // if (err.email) {
        this.error = 'Por favor ingresa un email valido';
        // }
      });
    }
  }

  noEqual( control: FormControl ): { [ s: string ]: boolean } {
    const forma: any = this;
    if (control.value !== forma.controls['password'].value) {
      return {
        noEqual: true
      };
    }
    return null;
  }


}
