import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

// Module
import { PagesModule } from './pages/pages.module';

import { CoreModule } from './core/core.module';

// Routes
import { APP_ROUTES } from './app.routes';
import { SearchPipe } from './search.pipe';

// Forms
import { FormsModule } from '@angular/forms';

// Unir ONG
import { UnirOngModule } from './pages/unir-ong/unir-ong.module';


// Unir ONG
import { UnirNecesidadModule } from './pages/unir-necesidad/unir-necesidad.module';

// Auth module
import { AuthModule } from './auth/auth.module';

// HTTP client
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './core/guards/auth.guard';






@NgModule({
  declarations: [
    AppComponent,
    SearchPipe,
  ],
  imports: [
    BrowserModule,
    PagesModule,
    APP_ROUTES,
    CoreModule,
    FormsModule,
    AuthModule,
    UnirOngModule,
    UnirNecesidadModule,
    HttpClientModule,
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
