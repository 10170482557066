import { Component, OnInit } from '@angular/core';
import { OngService } from 'src/app/core/services/ong.service';
import { ONG } from 'src/app/core/models/ong.model';
import { Categoria } from 'src/app/core/models/categoria.model';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/app/core/environment/environment';

@Component({
  selector: 'app-listatabla',
  templateUrl: './listatabla.component.html',
  styleUrls: ['./listatabla.component.scss']
})
export class ListatablaComponent implements OnInit {

  // url global
  urldescarga = environment.api_url + 'csv/ongs/';

  listado: ONG[];
  categorias: Categoria[];
  // la categoria actual
  cat: Categoria;
  // el numero de paginas requerido
  paginas: number;
  // pagina actual
  pag: number;
  // la coleccion de paginas para iterar entre paginas con ngfor
  array: number[];
  // dice cual es el tipo de categoria actualmente
  tipoactual: string;
  // determina si esta cargando la lista
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    public ongService: OngService,
    public categoriaService: CategoriaService) { }

  ngOnInit() {
    this.loading = true;
    // console.log(this.urldescarga);
    this.tipoactual = 'Todas';
    this.categoriaService.getCategorias().subscribe((res: any) => this.categorias = res.results);
    this.ongService.getONGs().subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading = false));
  }

  buscar(event: any) {
    this.loading = true;
    this.ongService.getONGsNombre(event.target.value).subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading = false));
  }

  voluntarios() {
    this.loading = true;
    this.tipoactual = 'Voluntarias';
    this.ongService.getVoluntarios().subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading = false));
  }

  donantes() {
    this.loading = true;
    this.tipoactual = 'Donantes';
    this.ongService.getDonantes().subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading = false));
  }
  // trae donantes o voluntarios por igual
  todosTipos() {
    this.loading = true;
    this.tipoactual = 'Todas';
    this.ongService.getDonantes().subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading = false));
  }
  // filtra por categoria
  getcategoria(id: string) {
    this.loading = true;
    this.categoriaService.getCategoria(id).subscribe((res: Categoria) => this.cat = res);
    this.ongService.getporCategoria(id).subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading = false));
  }
  // quita el filtro por categoria
  resetCategoria() {
    this.loading = true;
    this.cat = null;
    this.ongService.resetCategoria().subscribe((res: any) => (this.listado = res.results,
      this.paginas = Math.trunc(res.count / 24) + 1,
      this.array = Array.from(new Array(this.paginas), (x, i) => i + 1),
      this.pag = 1,
      this.loading = false));
  }
  pagina(page: number) {
    this.loading = true;
    this.pag = page;
    this.ongService.paginar(page).subscribe((res: any) => (this.listado = res.results,
      this.loading = false));
  }

}
