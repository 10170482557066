import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private _userservice: UserService,
  
  ) {
    
  }

  logeado: boolean = null;
  usuario;
    public isMenuCollapsed = true;

  ngOnInit() {
    
    if (localStorage.currentUser != null) {
      this.logeado = true;
      this.usuario = localStorage.currentUser;
    } else {
      this.logeado = false;
    }
    this._userservice.isAuthenticated.subscribe(res => {
      if (res) {
        this.logeado = true;
        this.usuario = localStorage.currentUser;
      } else {
        this.logeado = false;
      }
    });
  }
}
