import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listafooter',
  templateUrl: './listafooter.component.html',
  styleUrls: ['./listafooter.component.scss']
})
export class ListafooterComponent implements OnInit {

  // define el tipo de ongs a traer (donantes o voluntarios)
  tipoONG: string;
  // define el tipo de lista (que componente debe llamar)
  tipoLista: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const ruta: any = this.route.url;
    // dependiendo de la url llama a un listado de donantes o voluntarios
    if (ruta.value[0].path === 'ongs-donantes') {
      this.tipoLista = 'lista';
      this.tipoONG = 'donantes';
    } else if (ruta.value[0].path === 'ongs-voluntarios') {
      this.tipoLista = 'lista';
      this.tipoONG = 'voluntarios';
    } else if (ruta.value[0].path === 'mapa-donantes') {
      this.tipoLista = 'mapa';
      this.tipoONG = 'donantes';
    } else if (ruta.value[0].path === 'mapa-voluntarios') {
      this.tipoLista = 'mapa';
      this.tipoONG = 'voluntarios';
    } else if (ruta.value[0].path === 'mapa-necesidades') {
      this.tipoLista = 'mapa';
      this.tipoONG = 'necesidades';
    }
  }

}
